// Define the MeasureResult type locally
type MeasureResult = Array<{ time: number; value: number }>

/**
 * Normalizes time series data to ensure all series have values at every time point
 * within the specified range. Missing values are filled with the previous value
 * of that series, or the start value if no previous value exists.
 *
 * @param timeSeriesData Object containing time series data for multiple keys
 * @param startTime Start time of the range (timestamp)
 * @param endTime End time of the range (timestamp)
 * @returns Normalized time series data with values at all time points for all keys
 */
const normalizeTimeSeriesData = (
  timeSeriesData: { [key: string]: MeasureResult },
  startTime: number,
  endTime: number
): { [key: string]: MeasureResult } => {
  // Step 1: Collect all unique time points across all series
  const uniqueTimePoints = collectUniqueTimePoints(timeSeriesData, startTime, endTime)

  // Step 2: Create a normalized dataset with all series having values at all time points
  return createNormalizedDataset(timeSeriesData, uniqueTimePoints)
}

/**
 * Collects all unique time points from all series within a specified time range.
 * Also adds the start and end time points to ensure complete coverage.
 */
const collectUniqueTimePoints = (
  timeSeriesData: { [key: string]: MeasureResult },
  startTime: number,
  endTime: number
): number[] => {
  const uniqueTimePoints = new Set<number>()

  // Always include start and end times
  uniqueTimePoints.add(startTime)
  uniqueTimePoints.add(endTime)

  // Add all time points from all series within the range
  Object.values(timeSeriesData).forEach(timeSeries => {
    timeSeries.forEach(dataPoint => {
      if (dataPoint.time >= startTime && dataPoint.time <= endTime) {
        uniqueTimePoints.add(dataPoint.time)
      }
    })
  })

  // Return sorted time points
  return Array.from(uniqueTimePoints).sort((a, b) => a - b)
}

/**
 * Creates a normalized dataset where each series has values at all collected time points.
 * Missing values are filled using the previous value strategy.
 */
const createNormalizedDataset = (
  timeSeriesData: { [key: string]: MeasureResult },
  sortedTimePoints: number[]
): { [key: string]: MeasureResult } => {
  const normalizedData: { [key: string]: MeasureResult } = {}

  // Process each key (series) in the original data
  Object.keys(timeSeriesData).forEach(seriesKey => {
    const originalSeries = timeSeriesData[seriesKey]
    normalizedData[seriesKey] = normalizeIndividualSeries(originalSeries, sortedTimePoints)
  })

  return normalizedData
}

/**
 * Normalizes a single time series to have values at all specified time points.
 * Uses previous value strategy for filling gaps.
 */
const normalizeIndividualSeries = (originalSeries: MeasureResult, targetTimePoints: number[]): MeasureResult => {
  // Create a map for quick value lookup by time
  const timeToValueMap = new Map<number, number>()
  originalSeries.forEach(dataPoint => {
    timeToValueMap.set(dataPoint.time, dataPoint.value)
  })

  // Find the first value in the series (if it exists)
  const firstDataPoint = originalSeries[0]
  let currentValue = firstDataPoint ? firstDataPoint.value : 0

  // Create normalized series with values at all time points
  const normalizedSeries: MeasureResult = []

  for (const timePoint of targetTimePoints) {
    if (timeToValueMap.has(timePoint)) {
      // Use actual value if available at this time point
      currentValue = timeToValueMap.get(timePoint)!
    }
    // Always add a point with either the actual or filled value
    normalizedSeries.push({ time: timePoint, value: currentValue })
  }

  return normalizedSeries
}

/**
 * Flattens time series data from multiple series into a unified format.
 *
 * Input format:
 * {
 *   "series1": [{ time: 1, value: 10 }, { time: 2, value: 20 }],
 *   "series2": [{ time: 1, value: 100 }, { time: 2, value: 200 }]
 * }
 *
 * Output format:
 * [
 *   { time: 1, series1: 10, series2: 100 },
 *   { time: 2, series1: 20, series2: 200 }
 * ]
 *
 * @param timeSeriesData Object containing multiple time series data
 * @returns Array of flattened objects with time and values from each series
 */
const flattenTimeSeriesData = (timeSeriesData: {
  [key: string]: MeasureResult
}): Array<{ time: number; [key: string]: number }> => {
  // If input is empty, return empty array
  if (!timeSeriesData || Object.keys(timeSeriesData).length === 0) {
    return []
  }

  // Create a map to group values by timestamp
  const timeMap = new Map<number, { time: number; [key: string]: number }>()

  // Process each series
  Object.entries(timeSeriesData).forEach(([seriesKey, timeSeries]) => {
    // For each data point in the series
    timeSeries.forEach(dataPoint => {
      const { time, value } = dataPoint

      // If this is the first series with this timestamp
      if (!timeMap.has(time)) {
        timeMap.set(time, { time })
      }

      // Add the value for this series at this timestamp
      const entry = timeMap.get(time)!
      entry[seriesKey] = value
    })
  })

  // Convert map to array and sort by timestamp
  return Array.from(timeMap.values()).sort((a, b) => a.time - b.time)
}

export { normalizeTimeSeriesData, flattenTimeSeriesData }
