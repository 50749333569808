/**
 * Utility functions for binary operations and bit manipulation
 */

/**
 * Extract a bit value from a number at the specified position
 *
 * @param value - The number to extract the bit from
 * @param position - The position of the bit to extract (0-based)
 * @returns The value of the bit (0 or 1)
 */

/* tslint:disable:no-bitwise */
function getBitValue(value: number, position: number): number {
  if (position < 0 || position > 31) {
    throw new Error(`Bit position ${position} is out of range (must be 0-31)`)
  }

  // Use bitwise operations for better performance
  return (value >> position) & 1
}
/* tslint:enable:no-bitwise */

/**
 * Validates that a value is a valid bit (0 or 1)
 *
 * @param value - The value to validate
 * @returns true if the value is valid, false otherwise
 */
function isValidBitValue(value: number): boolean {
  return value === 0 || value === 1
}

/**
 * Set a bit at the specified position in a number
 *
 * @param value - The original number
 * @param position - The position to set (0-based)
 * @param bitValue - The bit value to set (0 or 1)
 * @returns The new number with the bit set
 */

/* tslint:disable:no-bitwise */
function setBitValue(value: number, position: number, bitValue: number): number {
  if (position < 0 || position > 31) {
    throw new Error(`Bit position ${position} is out of range (must be 0-31)`)
  }

  if (!isValidBitValue(bitValue)) {
    throw new Error(`Bit value ${bitValue} is invalid (must be 0 or 1)`)
  }

  if (bitValue === 1) {
    // Set the bit
    return value | (1 << position)
  } else {
    // Clear the bit
    return value & ~(1 << position)
  }
}
/* tslint:enable:no-bitwise */

export { getBitValue, isValidBitValue, setBitValue }
