/**
 * Logic for derived measures calculations
 */
import {
  BitMeasure,
  CommandInputData,
  CommandValue,
  DerivedMeasure,
  DirectionInputData,
  DirectionValue,
  TimedBitData,
  ValvePositionInputData,
  ValvePositionValue,
  WordSlug,
} from './types'
/**
 * Calculates the command value based on input data
 *
 * @param data - The input data containing bit values
 * @returns The command value, or undefined if it can't be determined
 */
function calculateCommand(data: CommandInputData): number | undefined {
  if (data[BitMeasure.Manual] === 1) {
    return CommandValue.Manual
  }

  if (data[BitMeasure.Automatic] === 1) {
    return CommandValue.Automatic
  }

  if (data[BitMeasure.Local] === 1) {
    return CommandValue.Local
  }

  if (data[BitMeasure.Sectioned] === 1) {
    return CommandValue.Sectioned
  }

  return undefined
}

/**
 * Calculates the direction value based on input data
 *
 * @param data - The input data containing bit values
 * @returns The direction value, or undefined if it can't be determined
 */
function calculateDirection(data: DirectionInputData): number | undefined {
  if (data[BitMeasure.Backward] === 1) {
    return DirectionValue.Backward
  }

  if (data[BitMeasure.Forward] === 1) {
    return DirectionValue.Forward
  }

  if (data[BitMeasure.Forward] === 0 && data[BitMeasure.Backward] === 0) {
    return DirectionValue.Stopped
  }

  return undefined
}

/**
 * Calculates the valve position value based on input data
 *
 * @param data - The input data containing bit values
 * @returns The valve position value, or undefined if it can't be determined
 */
function calculateValvePosition(data: ValvePositionInputData): number | undefined {
  if (data[BitMeasure.ValveClosedPos] === 1) {
    return ValvePositionValue.Closed
  }

  if (data[BitMeasure.ValveOpenPos] === 1) {
    return ValvePositionValue.Open
  }

  return undefined
}

/**
 * Type for the derived measure calculator functions
 */
type DerivedMeasureCalculator<T extends TimedBitData> = (data: T) => number | undefined

/**
 * Map of derived measures to their calculation functions
 */
const DERIVED_MEASURE_CALCULATORS: {
  [key in DerivedMeasure]: {
    requiredBits: BitMeasure[]
    calculator: DerivedMeasureCalculator<any>
  }
} = {
  [DerivedMeasure.Command]: {
    requiredBits: [BitMeasure.Manual, BitMeasure.Automatic, BitMeasure.Local, BitMeasure.Sectioned],
    calculator: calculateCommand,
  },
  [DerivedMeasure.Direction]: {
    requiredBits: [BitMeasure.Forward, BitMeasure.Backward],
    calculator: calculateDirection,
  },
  [DerivedMeasure.ValvePosition]: {
    requiredBits: [BitMeasure.ValveOpenPos, BitMeasure.ValveClosedPos],
    calculator: calculateValvePosition,
  },
}

/**
 * Checks if the input data contains all required bits for a derived measure
 *
 * @param data - The input data
 * @param requiredBits - The required bits
 * @returns true if all required bits are present, false otherwise
 */
function hasRequiredBits(data: TimedBitData, requiredBits: BitMeasure[]): boolean {
  return requiredBits.every(bit => data[bit] !== undefined)
}

/**
 * Calculates all specified derived measures from the input data
 *
 * @param derivedMeasures - The derived measures to calculate
 * @param data - The input data containing bit values
 * @returns An object mapping derived measures to their calculated values
 */
function calculateDerivedMeasures(
  derivedMeasures: DerivedMeasure[],
  data: TimedBitData
): Record<string, number | undefined> {
  const result: Record<string, number | undefined> = {}

  derivedMeasures.forEach(measure => {
    const { requiredBits, calculator } = DERIVED_MEASURE_CALCULATORS[measure]
    if (hasRequiredBits(data, requiredBits)) {
      result[measure] = calculator(data)
    }
  })

  return result
}

/**
 * Get the derived measures for a given word slug
 *
 * @param wordSlug - The word slug
 * @returns The derived measures
 */
function getDerivedWordMeasures(wordSlug: WordSlug) {
  const derivedMeasures: DerivedMeasure[] = []

  if (wordSlug === WordSlug.MDIR || wordSlug === WordSlug.INV) {
    derivedMeasures.push(DerivedMeasure.Command)
  }

  if (wordSlug === WordSlug.INV) {
    derivedMeasures.push(DerivedMeasure.Direction)
  }

  if (wordSlug === WordSlug.KH) {
    derivedMeasures.push(DerivedMeasure.ValvePosition)
  }

  return derivedMeasures
}

export {
  calculateCommand,
  calculateDirection,
  calculateValvePosition,
  calculateDerivedMeasures,
  DERIVED_MEASURE_CALCULATORS,
  getDerivedWordMeasures,
}
