import React, { useMemo } from 'react'
import { ComposedChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { COLORS } from '../_colors'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { getAreaPauses } from '../../../../../../functions/shared'
import { TFunction } from 'i18next'
import { getAreaSections, reduceWarnAreas } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2'
import { Workshift } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/workshift'

export interface OwnProps {
  filteredData: any
  i18nTitle: string
  i18nLabelPrefix?: string
  tooltip?: boolean
  t: TFunction
  topMargin?: boolean
  entry: string
  colorsId: keyof typeof COLORS
  pauses?: Array<{ start: number; end: number }>
  lineHeight?: number
  hideTitle?: boolean
  hideTimeline?: boolean
  workshift?: Workshift
}

type Props = WithTranslation & OwnProps

const BufferLineChartSingleLineView = (props: Props) => {
  const { colorsId, pauses, hideTimeline, filteredData, i18nTitle, lineHeight, hideTitle, t, entry, workshift } = props

  const colors = useMemo(() => COLORS[colorsId], [colorsId])
  const dataAreas = useMemo(
    () => reduceWarnAreas(getAreaSections(filteredData, entry), { colorMap: colors, xAxisId: '1' }),
    [filteredData, entry]
  )
  const warnPauses = useMemo(
    () => pauses && reduceWarnAreas(getAreaPauses(pauses), { color: '#ff00ff', xAxisId: '1' }),
    [pauses]
  )

  return (
    <div className={'w-100'}>
      {/* <div className="col" style={paddingRight ? { paddingRight } : {}}> */}
      {!hideTitle && <h5>{t(i18nTitle)}</h5>}
      <ResponsiveContainer width="100%" height={lineHeight ?? 20}>
        <ComposedChart data={filteredData} barGap={0} barCategoryGap={0}>
          <YAxis hide={true} label={''} domain={[0, 100]} tick={false} axisLine={false} tickLine={false} />
          <XAxis
            xAxisId={'1'}
            dataKey="time"
            hide={true}
            domain={['dataMin', 'dataMax']}
            type={'number'}
            interval={0}
          />
          {dataAreas}
          {warnPauses}
        </ComposedChart>
      </ResponsiveContainer>
      {!hideTimeline && (
        <ResponsiveContainer width="100%" height={20}>
          <ComposedChart data={filteredData} barGap={0} barCategoryGap={0}>
            <XAxis
              height={20}
              dataKey="time"
              tickLine={false}
              axisLine={false}
              tickFormatter={timeStr => moment.unix(timeStr).format('HH:mm')}
              domain={['dataMin', 'dataMax']}
              type={'number'}
              interval={'preserveStartEnd'}
              tickCount={workshift?.name === 'workshift.99' ? 26 : 33}
              allowDataOverflow={false}
              xAxisId={'2'}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
      {/* </div> */}
    </div>
  )
}

export default withTranslation()(BufferLineChartSingleLineView)
