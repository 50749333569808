/**
 * All supported word slugs used in the GenericBinarySrcGraph component interface
 */
enum WordSlug {
  MDIR = "MDIR",
  INV = "INV",
  SYNC = "SYNC",
  KH = "KH",
  PKEY = "PKEY"
}

/**
 * Raw measures that are read directly from the bits of a word
 */
enum BitMeasure {
  // Command-related bits
  Manual = "manual",
  Automatic = "automatic",
  Local = "local",
  Sectioned = "sectioned",
  
  // Operation-related bits
  Running = "running",
  Forward = "forward",
  Backward = "backward",
  Sync = "sync",
  
  // Valve-related bits
  ValveOpenPos = "valveOpenPos",
  ValveClosedPos = "valveClosedPos",
  
  // Key-related bits
  Key1Inserted = "key1Inserted",
  Key2Inserted = "key2Inserted",
  Key3Inserted = "key3Inserted",
  Key4Inserted = "key4Inserted",
  Key5Inserted = "key5Inserted",
  Key6Inserted = "key6Inserted",
  Key7Inserted = "key7Inserted",
  Key8Inserted = "key8Inserted",
  Key9Inserted = "key9Inserted",
  Key10Inserted = "key10Inserted",
  Key11Inserted = "key11Inserted",
  Key12Inserted = "key12Inserted",
  Key13Inserted = "key13Inserted",
  Key14Inserted = "key14Inserted"
}

/**
 * Measures that can be read from binary words
 */
enum WordMeasure {
  Running = "running",
  Command = "command",
  Direction = "direction",
  Sync = "sync",
  ValvePosition = "valvePosition",
  Key1Inserted = "key1Inserted",
  Key2Inserted = "key2Inserted",
  Key3Inserted = "key3Inserted",
  Key4Inserted = "key4Inserted",
  Key5Inserted = "key5Inserted",
  Key6Inserted = "key6Inserted",
  Key7Inserted = "key7Inserted",
  Key8Inserted = "key8Inserted",
  Key9Inserted = "key9Inserted",
  Key10Inserted = "key10Inserted",
  Key11Inserted = "key11Inserted",
  Key12Inserted = "key12Inserted",
  Key13Inserted = "key13Inserted",
  Key14Inserted = "key14Inserted"
}

/**
 * Measures that can be read from influx (not encoded in binary words)
 */
enum AdditionalMeasure {
  Amperage = "amperage",
  RotorSpeed = "rotorSpeed",
  WaterFlow = "waterFlow",
  MaterialTemperature = "materialTemperature",
  PressorThrust = "pressorThrust",
  Program = "program",
  BufferLoadedWithF015 = "bufferLoadedWithF015",
  BufferLoadedWithF070 = "bufferLoadedWithF070"
}

/**
 * Derived measures resulting from combination of bit measures
 */
enum DerivedMeasure {
  Direction = "direction",
  Command = "command",
  ValvePosition = "valvePosition"
}

/**
 * Command values returned by the system
 */
enum CommandValue {
  Manual = 0,
  Automatic = 1,
  Local = 2,
  Sectioned = 3
}

/**
 * Direction values returned by the system
 */
enum DirectionValue {
  Backward = 0,
  Forward = 1,
  Stopped = 2
}

/**
 * Valve position values returned by the system
 */
enum ValvePositionValue {
  Closed = 0,
  Open = 1
}

/**
 * Union type of all supported measures
 */
type SupportedMeasure = WordMeasure | AdditionalMeasure;

/**
 * Type for binary word timed data with required bit measures
 */
type TimedBitData = {
  time: number;
} & {
  [key in BitMeasure]?: number;
};

/**
 * Type for command calculation input
 */
interface CommandInputData {
  time: number;
  [BitMeasure.Manual]: number;
  [BitMeasure.Automatic]: number;
  [BitMeasure.Local]: number;
  [BitMeasure.Sectioned]: number;
};

/**
 * Type for direction calculation input
 */
interface DirectionInputData  {
  time: number;
  [BitMeasure.Forward]: number;
  [BitMeasure.Backward]: number;
};

/**
 * Type for valve position calculation input
 */
interface ValvePositionInputData  {
  time: number;
  [BitMeasure.ValveOpenPos]: number;
  [BitMeasure.ValveClosedPos]: number;
};

/**
 * Union type for all derived measure inputs
 */
type DerivedMeasureInput = CommandInputData | DirectionInputData | ValvePositionInputData;

// Export all types
type WordSlugType = keyof typeof WordSlug;
type BitMeasureType = keyof typeof BitMeasure;
type WordMeasureType = keyof typeof WordMeasure;
type AdditionalMeasureType = keyof typeof AdditionalMeasure;
type DerivedMeasureType = keyof typeof DerivedMeasure;
type CommandValueType = keyof typeof CommandValue;
type DirectionValueType = keyof typeof DirectionValue;
type ValvePositionValueType = keyof typeof ValvePositionValue;

export {
  WordSlug,
  BitMeasure,
  WordMeasure,
  AdditionalMeasure,
  DerivedMeasure,
  CommandValue,
  DirectionValue,
  ValvePositionValue
};

export type {
  WordSlugType,
  BitMeasureType,
  WordMeasureType,
  AdditionalMeasureType,
  DerivedMeasureType,
  SupportedMeasure,
  TimedBitData,
  CommandInputData,
  DirectionInputData,
  ValvePositionInputData,
  DerivedMeasureInput,
  CommandValueType,
  DirectionValueType,
  ValvePositionValueType
}; 