import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { COLORS } from '../_colors'

interface OwnProps {
  // A list of measures whose information you want to print in the bottom legend
  measuresSlugs: string[]
}

type Props = OwnProps & WithTranslation

interface MeasureWithColors {
  measureSlug: string
  colors: Record<number, string>
}

class GenericBottomLegend extends React.Component<Props> {
  public render() {
    if (this.props.measuresSlugs.length === 0) {
      return <></>
    }

    const labelsWithoutLegendDuplicates = this._mapLabelsToLegendColors(this.props.measuresSlugs)

    return (
      <div className={'row row-legend row-legend-bottom'}>
        <div className="col-12">
          {labelsWithoutLegendDuplicates.map(legend => {
            if (!legend.colors) {
              return <></>
            }
            return (
              <>
                <h3>{this.props.t(`plantAnalysis.legendTitles.${legend.measureSlug}`)}</h3>
                <ul>
                  {Object.entries(legend.colors).map(([index, color]) =>
                    this._renderLegendElement(legend.measureSlug, index, color)
                  )}
                </ul>
              </>
            )
          })}
        </div>
      </div>
    )
  }

  /**
   * Renders a square of a given color and the corresponding measure state label
   */
  private _renderLegendElement(measureSlug: string, index: string, color: string) {
    const measureTranslation = this._getMeasureStateLegendTranslation(measureSlug, index)
    return (
      <li key={index} title={measureTranslation}>
        <span className="label-color label-color-square" style={{ backgroundColor: color }} />
        <span>{measureTranslation}</span>
      </li>
    )
  }

  /**
   * For every `measureSlug` retrieves corresponding colors
   * @returns {MeasureWithColor[]}
   */
  private _mapLabelsToLegendColors(measuresSlugs: string[]): MeasureWithColors[] {
    return measuresSlugs.reduce<MeasureWithColors[]>((acc, measureSlug) => {
      const measureColors = this._getMeasureColors(measureSlug)
      // Remove duplicates (es. if multiple measures have same legend, prints the legend only once)
      if (acc.find(a => Object.is(a.colors, measureColors))) {
        return [...acc]
      }
      return [...acc, { measureSlug, colors: measureColors }]
    }, [])
  }

  private _getMeasureColors(measureSlug: string) {
    return COLORS[measureSlug]
  }

  private _getMeasureStateLegendTranslation(measureSlug: string, index: string) {
    return this.props.t(`plantAnalysis.legend.${measureSlug}.${index}`)
  }
}

export default withTranslation()(GenericBottomLegend)
