import { getStartAndEndOfShift } from '../workshift'
import { Workshift } from '../../types/workshift'
import moment from 'moment'
import { API } from '@mv-submodules/inplant-plantanalysis-fe-iblu/redux/actions'

// Types for measurement data
export interface MeasurePoint {
  time: string
  measure: number | string
}

export interface CalculatedMeasurePoint {
  time: string
  shift: number
  [key: string]: any // Additional fields for calculated measures
}

// Response types
export interface MeasureResponse {
  data: MeasurePoint[]
  lastFromPreviousShift?: MeasurePoint | null
}

export interface CalculatedMeasureResponse {
  data: CalculatedMeasurePoint[]
}

// Request parameter interfaces
export interface MeasureRequestParams {
  assetId: string
  measureId: string
  workshift: Workshift
  date: string | number
  plant?: string
  /**
   * Reduces and groups data entries by the specified {@link aggregateTimeMinutes} interval.
   */
  aggregate?: boolean
  aggregateByMinutes?: number
}

export interface CalculatedMeasureRequestParams {
  measureId: string
  workshift: Workshift
  date: string | number
  plant?: string
}

// Influx response format
interface InfluxResponse {
  results: Array<{
    statement_id: number
    series?: Array<{
      name: string
      columns: string[]
      values: any[][]
    }>
  }>
}

interface Options {
  abortController?: AbortController
  includeLastValueFromPreviousShift?: boolean
}

class InfluxDataFetcher {
  private abortController?: AbortController
  private includeLastValueFromPreviousShift?: boolean
  constructor(options: Options) {
    this.abortController = options.abortController
    this.includeLastValueFromPreviousShift = options.includeLastValueFromPreviousShift
  }

  /**
   * Maps the workshift value to the appropriate shift number for influx queries
   * @param workshiftValue The current workshift value
   * @returns The shift number for influx (0 for all day, 1-3 for specific shifts)
   */
  private mapWorkshiftValueToShift(workshiftValue: string | number): number {
    // Convert to number if it's a string
    const numValue = typeof workshiftValue === 'string' ? parseInt(workshiftValue, 10) : workshiftValue

    // Map 99 (all day) to 0 for influx queries
    return numValue === 99 ? 0 : numValue
  }

  private formatDate(date: string | number): string {
    return moment(date)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
      .toString()
  }

  /**
   * Extracts and transforms data from the Influx response
   * @param response The parsed Influx response
   * @param measureField The field name for the measure value
   * @returns Transformed array of data points
   */
  private extractMeasureData(response: InfluxResponse, measureField: string = 'measure'): MeasurePoint[] {
    const data: MeasurePoint[] = []

    // Extract data from the response structure
    if (response.results && response.results.length > 0) {
      const result = response.results[0]

      if (result.series && result.series.length > 0) {
        const series = result.series[0]
        const timeIndex = series.columns.indexOf('time')
        const measureIndex = series.columns.indexOf(measureField)

        if (timeIndex !== -1 && measureIndex !== -1) {
          series.values.forEach(value => {
            data.push({
              time: value[timeIndex],
              measure: value[measureIndex],
            })
          })
        }
      }
    }

    return data
  }

  /**
   * Extracts and transforms data from the Influx response for calculated measures
   * @param response The parsed Influx response
   * @returns Transformed array of calculated data points
   */
  private extractCalculatedMeasureData(response: InfluxResponse): CalculatedMeasurePoint[] {
    const data: CalculatedMeasurePoint[] = []

    // Extract data from the response structure
    if (response.results && response.results.length > 0) {
      const result = response.results[0]

      if (result.series && result.series.length > 0) {
        const series = result.series[0]
        const timeIndex = series.columns.indexOf('time')
        const shiftIndex = series.columns.indexOf('shift')

        if (timeIndex !== -1 && shiftIndex !== -1) {
          series.values.forEach(value => {
            const point: CalculatedMeasurePoint = {
              time: value[timeIndex],
              shift: value[shiftIndex],
            }

            // Add all other columns
            series.columns.forEach((column, index) => {
              if (column !== 'time' && column !== 'shift') {
                point[column] = value[index]
              }
            })

            data.push(point)
          })
        }
      }
    }

    return data
  }

  /**
   * Fetch normal measurement data
   * @param params Request parameters for the measure
   * @returns Promise with the measurement data
   */
  public async requestMeasure(params: MeasureRequestParams): Promise<MeasureResponse> {
    const { assetId, measureId, workshift, date, plant, aggregate, aggregateByMinutes = 1 } = params

    const measureName = `${assetId}_${measureId}`
    const plantQueryString = plant && plant !== '' ? `plant=${plant}&` : ''
    const [startOfShift, endOfShift] = getStartAndEndOfShift(workshift, date)

    const result: MeasureResponse = {
      data: [],
    }

    const queryStart = aggregate ? `SELECT PERCENTILE("measure", 95) AS "measure" FROM` : `SELECT "measure" FROM`

    // Get current shift data
    const query =
      `${queryStart} "${measureName}" WHERE time >= '${this.formatDate(startOfShift)}' AND time <= '${this.formatDate(
        endOfShift
      )}'` + (aggregate ? ` GROUP BY time(${aggregateByMinutes}m) fill(linear)` : '')

    const response = await API().request(`/query?${plantQueryString}q=${query}`, {
      signal: this.abortController?.signal,
    })


    // Convert the response to the expected format
    if (response && typeof response === 'string') {
      const parsedResponse = JSON.parse(response) as InfluxResponse
      result.data = this.extractMeasureData(parsedResponse)
    }

    if (this.includeLastValueFromPreviousShift) {
      // If includeLastValueFromPreviousShift is true, get the last result from previous shift
      // and add it to the beginning of the data array
      const previousShiftEndQuery = `SELECT "measure" FROM "${measureName}" WHERE time < '${this.formatDate(
        startOfShift
      )}' ORDER BY time DESC LIMIT 1`
      const previousResponse = await API().request(`/query?${plantQueryString}q=${previousShiftEndQuery}`, {
        signal: this.abortController?.signal,
      })

      if (previousResponse && typeof previousResponse === 'string') {
        const parsedPrevious = JSON.parse(previousResponse) as InfluxResponse
        const lastValueFromPreviousShift = this.extractMeasureData(parsedPrevious)

        if (lastValueFromPreviousShift.length > 0) {
          // Add the last value from previous shift to the beginning of the data array
          result.data = [...lastValueFromPreviousShift, ...result.data]
        }
      }
    }

    return result
  }

  /**
   * Fetch calculated measurement data
   * @param params Request parameters for the calculated measure
   * @returns Promise with the calculated measurement data
   */
  public async requestCalculatedMeasure(params: CalculatedMeasureRequestParams): Promise<CalculatedMeasureResponse> {
    const { measureId, workshift, date, plant } = params

    const plantQueryString = plant && plant !== '' ? `plant=${plant}&` : ''
    const [startOfShift, endOfShift] = getStartAndEndOfShift(workshift, date)

    // Map workshift.value to the appropriate shift value for influx (0,1,2,3)
    const shiftValue = this.mapWorkshiftValueToShift(workshift.value)

    // For all day (shift 0), don't include the shift condition
    const shiftCondition = shiftValue !== 0 ? `shift = ${shiftValue} AND` : ''
    const query = `SELECT * FROM "${measureId}" WHERE ${shiftCondition} time >= '${this.formatDate(
      startOfShift
    )}' AND time <= '${this.formatDate(endOfShift)}'`

    const response = await API().request(`/query?${plantQueryString}q=${query}`, {
      signal: this.abortController?.signal,
    })

    const result: CalculatedMeasureResponse = {
      data: [],
    }

    // Convert the response to the expected format
    if (response && typeof response === 'string') {
      const parsedResponse = JSON.parse(response) as InfluxResponse
      result.data = this.extractCalculatedMeasureData(parsedResponse)
    }

    return result
  }
}

export default InfluxDataFetcher
