import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { ChartFetch } from '../../ChartRenderUtilities'
import { LineBufferProps, LineBufferState } from './_types'
import { lineBufferMeasuresToFetch } from './_measures'
import { getChartInfluxData } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx/getChartInfluxData'

abstract class LineBuffer<Props extends LineBufferProps, State extends LineBufferState> extends ChartFetch<
  Props,
  State
> {
  private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.lines.linesStatusTitle'

  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)
    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.mounted = true
    if (this.props.workShift) {
      this.getData()
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    this.abortController.abort()
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData()
      }
    }
  }

  private async getData() {
    const workshift = this.props.workShift
    if (workshift) {
      try {
        this.setState({
          fetching: true,
          error: false,
        })

        const promisesParsed = await getChartInfluxData(
          {
            measuresToFetch: lineBufferMeasuresToFetch,
            plant: this.props.plant.plant,
            date: this.props.date.toString(),
            workshift: this.props.workShift,
          },
          {
            abortController: this.abortController,
          }
        )

        if (this.mounted) {
          this.setState({
            filteredData: promisesParsed,
            fetching: false,
            error: false,
          })
        }
      } catch (error: any) {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.filteredData || this.state.filteredData.length === 0) {
      return this.renderNoData()
    }

    return this.renderContent()
  }
}

export default LineBuffer
