import { MeasureToFetch } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx/getChartInfluxData'

const lineBufferMeasuresToFetch: MeasureToFetch[] = [
  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF019ActiveStateTimeLine',
    measureSlug: 'buffer1State',
  },
  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF019AFullStateTimeLine',
    measureSlug: 'buffer1Fill',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vBufferF017LoadState',
    measureSlug: 'buffer1LoadState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vDensifierF019TorqueState',
    measureSlug: 'dec1TorqueState',
  },

  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF028ActiveStateTimeLine',
    measureSlug: 'buffer2State',
  },
  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF028AFullStateTimeLine',
    measureSlug: 'buffer2Fill',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vBufferF026LoadState',
    measureSlug: 'buffer2LoadState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vDensifierF028TorqueState',
    measureSlug: 'dec2TorqueState',
  },

  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF057ActiveStateTimeLine',
    measureSlug: 'buffer5State',
  },
  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF057AFullStateTimeLine',
    measureSlug: 'buffer5Fill',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vBufferF055LoadState',
    measureSlug: 'buffer5LoadState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vDensifierF057TorqueState',
    measureSlug: 'dec5TorqueState',
  },

  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF076ActiveStateTimeLine',
    measureSlug: 'buffer3State',
  },
  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF076AFullStateTimeLine',
    measureSlug: 'buffer3Fill',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vBufferF074LoadState',
    measureSlug: 'buffer3LoadState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vDensifierF076TorqueState',
    measureSlug: 'dec3TorqueState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vDensifierF086TorqueState',
    measureSlug: 'dec4TorqueState',
  },
  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF086ActiveStateTimeLine',
    measureSlug: 'buffer4State',
  },
  {
    type: 'single_column_state_serie',
    measureId: 'vBufferF086AFullStateTimeLine',
    measureSlug: 'buffer4Fill',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vBufferF084LoadState',
    measureSlug: 'buffer4LoadState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vMillF024AbsorptionState',
    measureSlug: 'mill1AbsorptionState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vMillF033AbsorptionState',
    measureSlug: 'mill2AbsorptionState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vMillF083AbsorptionState',
    measureSlug: 'mill3AbsorptionState',
  },
  {
    type: 'multi_column_state_serie',
    measureId: 'vMillF093AbsorptionState',
    measureSlug: 'mill4AbsorptionState',
  },
]

export { lineBufferMeasuresToFetch }
