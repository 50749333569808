import { Workshift } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/workshift'

import moment from 'moment'
import InfluxDataFetcher from './_InfluxDataFetcher'
import { flattenTimeSeriesData, normalizeTimeSeriesData } from './_utils'
import { parseInfluxResponse } from './_influxResponseParser'
import { WordSlug } from './measures-utils'
/**
 * Configuration for fetching a standard measure from Influx.
 * 
 * @property {string} assetId - Unique identifier of the asset
 * @property {string} measureId - Unique identifier of the measure to fetch
 * @property {string} measureSlug - Short name/identifier used as a key in the result data
 * @property {'standard'} type - Type identifier for standard measures
 */
interface StandardMeasureToFetch {
  assetId: string
  measureId: string
  measureSlug: string
  type: 'standard'
  aggregate?: boolean
  aggregateByMinutes?: number
}

/**
 * Configuration for fetching a calculated measure from Influx.
 * 
 * @property {'single_column_state_serie'|'multi_column_state_serie'} type - Type of calculated measure
 * @property {string} measureSlug - Short name/identifier used as a key in the result data
 * @property {string} measureId - Unique identifier of the calculated measure
 */
interface CalculatedMeasureToFetch {
  type: 'single_column_state_serie' | 'multi_column_state_serie'
  measureSlug: string
  measureId: string
}

/**
 * Configuration for fetching a word from Influx.
 * 
 * @property {string} wordId - Unique identifier of the word
 * @property {string} wordSlug - Short name/identifier for the word type (MDIR, INV, etc.)
 */
interface WordToFetch {
  type: 'word'
  assetId: string
  wordId: string
  wordSlug: WordSlug
}

/**
 * Union type representing either a standard or calculated measure to fetch.
 */
type MeasureToFetch = StandardMeasureToFetch | CalculatedMeasureToFetch | WordToFetch

/**
 * Time series data for a single measure, consisting of time-value pairs.
 */
type MeasureResult = Array<{ time: number; value: number }>

/**
 * Configuration options for fetching chart data from Influx.
 * 
 * @property {MeasureToFetch[]} measuresToFetch - List of measures to fetch data for
 * @property {string} plant - Plant identifier
 * @property {string} date - Date string for the request
 * @property {Workshift} workshift - Workshift object containing start and end times
 * @property {AbortController} [abortController] - Optional controller to abort request
 */
interface Props {
  measuresToFetch: MeasureToFetch[]
  plant: string
  date: string
  workshift: Workshift
}

interface Options {
  abortController?: AbortController
  includeLastValueFromPreviousShift?: boolean
}

interface ChartInfluxDataSingleEntry { time: number; [key: string]: number }
/**
 * Final chart data format where each object contains a timestamp and values for all measures.
 * Each object has a 'time' property and additional properties named after measure slugs.
 */
type ChartInfluxData = ChartInfluxDataSingleEntry[]

/**
 * Fetches and processes time series data from Influx for chart visualization.
 * 
 * This function retrieves data for multiple measures (standard or calculated) from Influx database,
 * normalizes the timestamps across all datasets, and combines them into a unified format suitable
 * for charts and visualizations.
 * 
 * @param {Options} options - Configuration options for the data fetch
 * @param {MeasureToFetch[]} options.measuresToFetch - Array of measures to fetch data for
 * @param {string} options.plant - Plant identifier
 * @param {string} options.date - Date string for the data request
 * @param {Workshift} options.workshift - Workshift object containing start and end times
 * @param {AbortController} [options.abortController] - Optional AbortController to cancel the request
 * 
 * @returns {Promise<ChartInfluxData>} Promise resolving to flattened time series data where each object
 *          contains a timestamp and values for each requested measure
 * 
 * @example
 * const data = await getChartInfluxData({
 *   measuresToFetch: [
 *     { type: 'standard', assetId: 'asset1', measureId: 'measure1', measureSlug: 'temp' },
 *     { type: 'single_column_state_serie', measureId: 'calc1', measureSlug: 'efficiency' }
 *   ],
 *   plant: 'plant1',
 *   date: '2023-04-01',
 *   workshift: { start: '2023-04-01T08:00:00', end: '2023-04-01T16:00:00' }
 * });
 * TODO: Add support for `summary` measure type
 */
const getChartInfluxData = async (props: Props, options: Options = {}): Promise<ChartInfluxData> => {
  const influxDataFetcher = new InfluxDataFetcher(options)
  const workshiftStart = moment(props.workshift.start).unix()
  const workshiftEnd = moment(props.workshift.end).unix()

  const results = await Promise.all(
    props.measuresToFetch.map(async measure => {
      if (measure.type === 'standard') {
        const response = await influxDataFetcher.requestMeasure({
          assetId: measure.assetId,
          measureId: measure.measureId,
          workshift: props.workshift,
          date: props.date,
          plant: props.plant,
          aggregate: measure.aggregate,
          aggregateByMinutes: measure.aggregateByMinutes
        })
        return {
          measureSlug: measure.measureSlug,
          data: parseInfluxResponse(response.data, { measureType: 'standard' }),
        }
      } else if (measure.type === 'word') {
        const response = await influxDataFetcher.requestMeasure({
          assetId: measure.assetId,
          measureId: measure.wordId,
          workshift: props.workshift,
          date: props.date,
          plant: props.plant,
        })
        return {
          measureSlug: measure.wordSlug,
          data: parseInfluxResponse(response.data, { measureType: 'word', wordSlug: measure.wordSlug }),
        }
      } else {
        const response = await influxDataFetcher.requestCalculatedMeasure({
          measureId: measure.measureId,
          workshift: props.workshift,
          date: props.date,
          plant: props.plant,
        })
        if (measure.type === 'single_column_state_serie') {
          return {
            measureSlug: measure.measureSlug,
            data: parseInfluxResponse(response.data, { measureType: 'single_column_state_serie' }),
          }
        }
        return {
          measureSlug: measure.measureSlug,
          data: parseInfluxResponse(response.data, { measureType: 'multi_column_state_serie' }),
        }
      }
    })
  )

  const resultToObject = results.reduce((acc, result) => {
    if(Array.isArray(result.data)) {
      acc[result.measureSlug] = result.data
    } else if (typeof result.data === 'object') {
      Object.entries(result.data).forEach(([key, value]) => {
        acc[key] = value
      })
    }
    return acc
  }, {} as { [key: string]: MeasureResult })

  const normalizedData = normalizeTimeSeriesData(resultToObject, workshiftStart, workshiftEnd)

  return flattenTimeSeriesData(normalizedData)
}

export { getChartInfluxData }
export type { MeasureResult, MeasureToFetch, ChartInfluxData, ChartInfluxDataSingleEntry }
