import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Column from '../../../../../../inplant-components-fe/ui/components/Grid/Column'
import GraphLineBarEnhanced from './_BufferLineChartSingleLine/BufferLineChartSingleLineView'
import LineBuffer from './LineBuffer'
import { LineBufferProps, LineBufferState } from './_types'
import { mapChartStateToProps } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/state'
import { DEFAULT_STATE, DENSIFIERS_IDS } from './_constants'
import Flex, { AlignItems, StretchColumn } from '@mv-submodules/mvlabs-components-fe/ui/components/Flex/Flex'
import LineBufferLegend from './_BufferLineChartLegend/BufferLineChartLegend'
import { Text } from '@mv-submodules/mvlabs-components-fe/ui/components'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
class LineBufferView extends LineBuffer<LineBufferProps, LineBufferState> {
  constructor(props: LineBufferProps) {
    super(props, () => DEFAULT_STATE)
  }

  private showMillAbsorptionState(lineNumber: number) {
    return !!this.state.filteredData?.[0]?.[`mill${lineNumber}AbsorptionState`]
  }

  protected renderContent() {
    const { t } = this.props

    return (
      <div className={`col-md-12 col-sm-12 col-lg-12 halts-graphs halts-graphs__vertical`}>
        <h3 className={'w-100'}>{this.props.t('plantAnalysis.lines.linesStatusTitle')}</h3>
        <div className={'graph-spacing column-break-before'}>
          <Row>
            <img
              src={'https://inplant-public.s3.eu-west-1.amazonaws.com/dec-bluair.png'}
              style={{ height: '230px', marginBottom: '15px' }}
            />
            <Column sm={10} md={10} lg={10}>
              <StretchColumn spaceSize="md">
                <StretchColumn spaceSize="none">
                  {DENSIFIERS_IDS.map(number => {
                    const showMillAbsorptionState = this.showMillAbsorptionState(number)
                    return (
                      <StretchColumn spaceSize="none" key={`densifier-${number}`}>
                        <h5 className={'w-100 mt-3'}>
                          {this.props.t(`plantAnalysis.lines.linesStatus.buffers.buffer${number}`)}
                        </h5>
                        <Flex alignItems={AlignItems.start}>
                          <div style={{ padding: '4px 0' }}>
                            <Text text="1." />
                          </div>
                          <GraphLineBarEnhanced
                            topMargin={true}
                            entry={`buffer${number}State`}
                            colorsId={'activeStatus'}
                            filteredData={this.state.filteredData}
                            i18nTitle={`plantAnalysis.lines.linesStatus.buffers.buffer${number}`}
                            lineHeight={30}
                            hideTitle={true}
                            hideTimeline={true}
                          />
                        </Flex>
                        <Flex alignItems={AlignItems.start}>
                          <div style={{ padding: '4px 0' }}>
                            <Text text="2." />
                          </div>
                          <GraphLineBarEnhanced
                            topMargin={true}
                            entry={`dec${number}TorqueState`}
                            colorsId={'densifierTorqueStatus'}
                            filteredData={this.state.filteredData}
                            i18nTitle={`plantAnalysis.lines.linesStatus.dec.dec${number}`}
                            lineHeight={30}
                            hideTitle={true}
                            hideTimeline={true}
                          />
                        </Flex>
                        <Flex alignItems={AlignItems.start}>
                          <div style={{ padding: '4px 0' }}>
                            <Text text="3." />
                          </div>
                          <GraphLineBarEnhanced
                            topMargin={true}
                            entry={`buffer${number}Fill`}
                            colorsId={'fillStatus'}
                            filteredData={this.state.filteredData}
                            i18nTitle={`plantAnalysis.lines.linesStatus.buffers.buffer${number}`}
                            lineHeight={30}
                            hideTitle={true}
                            hideTimeline={true}
                          />
                        </Flex>
                        <Flex alignItems={AlignItems.start}>
                          <div style={{ padding: '4px 0' }}>
                            <Text text="4." />
                          </div>
                          <GraphLineBarEnhanced
                            topMargin={true}
                            entry={`buffer${number}LoadState`}
                            colorsId={'bufferState'}
                            filteredData={this.state.filteredData}
                            i18nTitle={`plantAnalysis.vecoplant.bufferState.buffers.buffer${number}`}
                            lineHeight={30}
                            hideTitle={true}
                            workshift={this.props.workShift}
                            hideTimeline={showMillAbsorptionState}
                          />
                        </Flex>
                        {showMillAbsorptionState && (
                          <Flex alignItems={AlignItems.start}>
                            <div style={{ padding: '4px 0' }}>
                              <Text text="5." />
                            </div>
                            <GraphLineBarEnhanced
                              topMargin={true}
                              entry={`mill${number}AbsorptionState`}
                              colorsId={'millAbsorptionState'}
                              filteredData={this.state.filteredData}
                              i18nTitle={`plantAnalysis.vecoplant.bufferState.buffers.buffer${number}`}
                              lineHeight={30}
                              hideTitle={true}
                              workshift={this.props.workShift}
                            />
                          </Flex>
                        )}
                      </StretchColumn>
                    )
                  })}
                </StretchColumn>
                <LineBufferLegend t={t} />
              </StretchColumn>
            </Column>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(mapChartStateToProps)(withTranslation()(LineBufferView))
