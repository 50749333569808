import * as React from 'react'
import { Line } from 'recharts'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { DateRangeProps, Workshift } from '../../../../types/workshift'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Loader } from '../../../../functions/shared'
import BottomLegend from './_graphs/BottomLegend/GenericBottomLegend'
import GraphTimelineView from '../GraphTimeline/GraphTimelineView'
import GraphBrush from '../GraphBrush/GraphBrush'
import GenericLineGraph from './_graphs/LineGraph/GenericLineGraph'
import { debounce } from '../../../../../inplant-components-fe/mvfunctions/helpers'
import GenericTimeBarGraph from './_graphs/TimeBarGraph/GenericTimeBarGraph'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import {
  getMeasuresForWordSlug,
  WordMeasure,
  WordSlug,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx/measures-utils'
import {
  ChartInfluxData,
  getChartInfluxData,
  MeasureToFetch,
} from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx/getChartInfluxData'
import { MeasureToShow, WarnAreasData, WordToShow } from './_types'

interface OwnState {
  dayEnd: string | null
  dayStart: string | null
  endDate: string | number
  fetchErrors: boolean
  data: any[]
  filteredData: any[]
  isCollapsed: boolean
  isLoading: boolean
  startDate: string | number
  brushStartIndex: number
  brushEndIndex: number
}

interface OwnProps {
  active: string
  id: string
  toggleCollapse: (id: string) => void
  title?: string
  measures?: MeasureToShow[]
  binaryWords?: WordToShow[]
  warnAreas?: WarnAreasData[]
}

interface StateProps {
  plant: string | null
  workshift: Workshift
}

const mapStateToProps = (state: any): StateProps & DateRangeProps => ({
  dateFilterEnd: state.plantAnalysis.common.dateFilterEnd,
  dateFilterStart: state.plantAnalysis.common.dateFilterStart,
  days: state.plantAnalysis.common.days,
  isDateFilterRange: state.plantAnalysis.common.isDateFilterRange,
  plant: state.plantSelector.plant,
  workshift: state.plantAnalysis.common.workshift,
})

type Props = StateProps & OwnProps & WithTranslation & DateRangeProps

function initState() {
  return {
    brushStartIndex: 0,
    brushEndIndex: 0,
    dayEnd: null,
    dayStart: null,
    endDate: 'auto',
    fetchErrors: false,
    filteredData: [],
    data: [],
    isCollapsed: false,
    isLoading: false,
    startDate: 'auto',
  }
}

class GeneralBinarySrcGraph extends React.Component<Props, OwnState> {
  private abortController: AbortController = new AbortController()
  constructor(props: Props) {
    super(props)
    this.state = initState()
    this.exportData = this.exportData.bind(this)
    this.handleBrush = debounce(this.handleBrush.bind(this), 10)
  }

  public componentDidMount() {
    const date = moment.isMoment(this.props.dateFilterStart)
      ? this.props.dateFilterStart
      : moment(this.props.dateFilterStart)
    if (this.props.dateFilterStart && moment.isMoment(date) && this.props.workshift) {
      this.getData(this.props)
    }
  }

  public componentWillUnmount() {
    this.setState(initState())
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const date = moment.isMoment(nextProps.dateFilterStart)
      ? nextProps.dateFilterStart
      : moment(nextProps.dateFilterStart)
    if (
      (this.props.dateFilterStart !== nextProps.dateFilterStart ||
        this.props.workshift !== nextProps.workshift ||
        nextProps.active === this.props.id) &&
      nextProps.dateFilterStart &&
      moment.isMoment(date) &&
      nextProps.workshift
    ) {
      this.getData(nextProps)
    } else {
      if (this.state.filteredData && this.state.filteredData?.length > 0) {
        return
      }
      const cleanStateWithouFetch = (({ isLoading, fetchErrors, ...others }) => ({ ...others }))(initState())
      this.setState(cleanStateWithouFetch)
    }
  }

  private getMeasuresRepresentedByLine() {
    return (this.props.measures ?? []).filter(m => m.representation === 'line')
  }

  private getMeasuresRepresentedByTimeBar() {
    const binaryWordMeasures = (this.props.binaryWords ?? []).reduce<WordMeasure[]>(
      (acc, word) => [...acc, ...getMeasuresForWordSlug(word.type)],
      []
    )

    const timeBarMeasures = (this.props.measures ?? []).filter(m => m.representation === 'time-bar').map(m => m.slug)

    return [...binaryWordMeasures, ...timeBarMeasures]
  }

  public render() {
    const isReady = this.state.data !== undefined && this.state.data.length > 0 && !this.state.isLoading
    const noData = this.state.data !== undefined && this.state.data.length === 0 && !this.state.isLoading

    const measuresRepresentedByLine = this.getMeasuresRepresentedByLine()
    const measuresRepresentedByTimeBar = this.getMeasuresRepresentedByTimeBar()

    return (
      <div className="col-md-12 col-sm-12 col-lg-12">
        {this.props.title && (
          <div className="mb-3">
            <h3 className={'w-100'}>{this.props.title}</h3>
          </div>
        )}
        <div className="row">
          {noData && (
            <div className="alert alert-warning w-100 col-sm-6 mx-auto">
              {this.props.t('plantAnalysis.noDataAvailable')}
            </div>
          )}
          {this.state.isLoading && (
            <Row>
              <Loader />
            </Row>
          )}
          {this.state.fetchErrors && (
            <div className="alert alert-danger w-100 col-sm-6 mx-auto">{this.props.t('plantAnalysis.fetchErrors')}</div>
          )}
        </div>

        {isReady && measuresRepresentedByLine.length > 0 && (
          <div className="row">
            <GenericLineGraph
              additionalChartLines={measuresRepresentedByLine.map(m => ({
                type: m.unitOfMeasure === '%' ? 'perc' : 'value',
                measure: m.slug,
                unit: m.unitOfMeasure,
                color: m.color,
                label: m.label,
              }))}
              filteredData={this.state.filteredData}
              warnAreasData={this.props.warnAreas ?? []}
            />
          </div>
        )}

        {isReady &&
          measuresRepresentedByTimeBar.map(m => (
            <GenericTimeBarGraph topMargin={true} entry={m} filteredData={this.state.filteredData} paddingRight={74} />
          ))}

        {isReady && <GraphTimelineView data={this.state.filteredData} xAxisNumber={true} paddingRight={60} />}

        {isReady && <BottomLegend measuresSlugs={measuresRepresentedByTimeBar} />}

        {isReady && measuresRepresentedByLine.length > 0 && (
          <GraphBrush data={this.state.data} brushOnChange={this.handleBrush} paddingRight={70}>
            <Line
              dataKey={(this.props.measures ?? [])?.[0]?.slug}
              type="step"
              stroke="#A19FF9"
              dot={false}
              isAnimationActive={false}
            />
          </GraphBrush>
        )}

        {isReady && measuresRepresentedByLine.length > 0 && (
          <GraphTimelineView data={this.state.data} xAxisNumber={false} paddingRight={60} />
        )}
      </div>
    )
  }

  /**
   * Enhances the chart data adding for each measure a key for each warn area
   * @param data - The chart data
   */
  private enhanceChartDataWithWarnAreas(data: ChartInfluxData, warnAreas: WarnAreasData[]) {
    data.forEach(measure => {
      warnAreas.forEach(w => {
        if (measure[w.measure] === undefined) {
          return
        }
        const warnData = w.fn(measure[w.measure])
        measure[w.slug] = warnData ? 100 : 0
      })
    })
  }

  private async getData(props: Props) {
    try {
      this.setState({ isLoading: true })

      const measuresToFetch = (props.measures ?? []).map<MeasureToFetch>(m => ({
        assetId: m.assetId ?? props.id,
        measureId: m.id,
        measureSlug: m.slug,
        type: 'standard' as const,
      }))

      const binaryWordsToFetch = (props.binaryWords ?? []).map<MeasureToFetch>(w => ({
        assetId: w.assetId ?? props.id,
        wordId: w.id,
        type: 'word' as const,
        wordSlug: w.type as WordSlug,
      }))

      const measuresData = await getChartInfluxData(
        {
          measuresToFetch: [...measuresToFetch, ...binaryWordsToFetch],
          plant: props.plant ?? '',
          date: props.workshift.start,
          workshift: props.workshift,
        },
        { abortController: this.abortController, includeLastValueFromPreviousShift: true }
      )

      this.enhanceChartDataWithWarnAreas(measuresData, props.warnAreas ?? [])

      this.setState({
        startDate: moment(props.workshift.start).unix(),
        endDate: moment(props.workshift.end).unix(),
        isLoading: false,
        fetchErrors: false,
        data: measuresData,
        filteredData: measuresData,
        brushStartIndex: 0,
        brushEndIndex: measuresData.length,
      })
    } catch (error: any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        logoutUser()
      }
      this.setState({
        isLoading: false,
        fetchErrors: true,
      })
    }
  }

  private exportData(e: React.MouseEvent) {
    e.preventDefault()
  }

  private handleBrush(args: any) {
    const filteredData = this.state.data ? this.state.data.slice(args.startIndex, args.endIndex + 1) : []
    this.setState({
      brushStartIndex: args.startIndex,
      brushEndIndex: args.endIndex,
      filteredData,
    })
  }
}

export default withRouter<any, any>(connect(mapStateToProps, null)(withTranslation()(GeneralBinarySrcGraph)))
