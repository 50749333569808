import { MeasurePoint, CalculatedMeasurePoint } from './_InfluxDataFetcher'
import moment from 'moment'
import {
  WordSlug,
  getBitValue,
  getMeasuresForWordSlug,
  WORD_SLUG_TO_BIT_POSITION,
  BitMeasure,
  DerivedMeasure,
  calculateDerivedMeasures,
  TimedBitData,
  getDerivedWordMeasures,
} from './measures-utils'

interface ParseDefaultOptions {
  measureType?: 'standard' | 'single_column_state_serie' | 'multi_column_state_serie' | 'summary'
}

interface ParseWordOptions {
  measureType: 'word'
  wordSlug: WordSlug
}

type ParseOptions = ParseDefaultOptions | ParseWordOptions

interface ParsedSerieDatum {
  time: number
  value: number
}

// Type for summary data
type ParsedStateData = Record<string, number>

/**
 * Parse standard measure points
 *
 * @example
 * Example input data:
 * [
 *  { time: "2023-01-01T12:00:00Z", measure: 42.5 },
 *  { time: "2023-01-01T12:01:00Z", measure: "43.2" }
 * ]
 */
function parseStandardMeasure(data: MeasurePoint[]): ParsedSerieDatum[] {
  return data.map(point => ({
    time: moment(point.time).unix(),
    value: typeof point.measure === 'string' ? parseFloat(point.measure) : (point.measure as number),
  }))
}

/**
 * Parse single column state serie
 *
 * @example
 * Example input data:
 * [
 *   { time: "2023-01-01T12:00:00Z", shift: "morning", state: true },
 *   { time: "2023-01-01T12:01:00Z", shift: "morning", state: false }
 * ]
 */
function parseSingleColumnStateSerie(data: CalculatedMeasurePoint[]): ParsedSerieDatum[] {
  return data.map(point => {
    // Get the first property that's not time or shift
    const valueKey = Object.keys(point).find(key => key !== 'time' && key !== 'shift')

    if (!valueKey) {
      return { time: moment(point.time).unix(), value: 0 }
    }

    const value = point[valueKey]
    return {
      time: moment(point.time).unix(),
      value: typeof value === 'boolean' ? Number(value) : Number(value) || 0,
    }
  })
}

/**
 * Parse multi column state serie
 *
 * @example
 * Example input data:
 * [
 *   { time: "2023-01-01T12:00:00Z", shift: "morning", running: true, idle: false, stopped: false },
 *   { time: "2023-01-01T12:01:00Z", shift: "morning", running: false, idle: true, stopped: false }
 * ]
 */
function parseMultiColumnStateSerie(data: CalculatedMeasurePoint[]): ParsedSerieDatum[] {
  return data.map(point => {
    // Find the first column with a true value
    const valueKeys = Object.keys(point).filter(key => key !== 'time' && key !== 'shift')
    const trueValueIndex = valueKeys.findIndex(key => point[key] === true)

    return {
      time: moment(point.time).unix(),
      value: trueValueIndex !== -1 ? trueValueIndex : 0,
    }
  })
}

/**
 * Parse word measure into bit values and derived measures
 *
 * @example
 * Example input data:
 * [
 *   { time: "2023-01-01T12:00:00Z", measure: 42 },
 *   { time: "2023-01-01T12:01:00Z", measure: "37" }
 * ]
 */
function parseWordMeasure(data: MeasurePoint[], wordSlug: WordSlug): Record<string, ParsedSerieDatum[]> {
  if (!wordSlug || !Object.values(WordSlug).includes(wordSlug as any)) {
    return {}
  }

  const supportedMeasures = getMeasuresForWordSlug(wordSlug)

  if (supportedMeasures.length === 0) {
    return {}
  }

  // Final result will have a single time series per measure
  // First, process all points to get all bit values and derived measures
  const resultsByTime: Record<number, Record<string, number>> = {}

  // Determine which derived measures we need to calculate based on the word slug
  const derivedMeasuresToCalculate: DerivedMeasure[] = getDerivedWordMeasures(wordSlug)

  // Process each data point
  data.forEach(point => {
    const time = moment(point.time).unix()
    const wordValue = typeof point.measure === 'string' ? parseInt(point.measure, 10) : (point.measure as number)

    // Skip invalid values
    if (isNaN(wordValue)) {
      return
    }

    // Create a timed bit data object with all relevant bit values
    const bitData: TimedBitData = { time }
    const bitPositions = WORD_SLUG_TO_BIT_POSITION[wordSlug]

    // Extract all bit values for this word
    Object.entries(bitPositions).forEach(([bitName, position]) => {
      if (position !== undefined) {
        bitData[bitName as BitMeasure] = getBitValue(wordValue, position)
      }
    })

    // Calculate derived measures
    const derivedValues = calculateDerivedMeasures(derivedMeasuresToCalculate, bitData)

    // Initialize the time entry if it doesn't exist
    if (!resultsByTime[time]) {
      resultsByTime[time] = {}
    }

    // Store all derived measure values
    Object.entries(derivedValues).forEach(([measureName, value]) => {
      if (value !== undefined) {
        resultsByTime[time][measureName] = value as number
      }
    })

    // Store direct bit measures
    Object.entries(bitData).forEach(([bitName, bitValue]) => {
      if (bitName === 'time' || bitValue === undefined) {
        return
      }

      if (supportedMeasures.includes(bitName as any)) {
        resultsByTime[time][bitName] = bitValue
      }
    })
  })

  // Convert the grouped data to the final format
  // This merges all measures into a single time series
  const result: Record<string, ParsedSerieDatum[]> = {}

  // For each timestamp, generate a data point
  Object.entries(resultsByTime).forEach(([timeStr, values]) => {
    const time = parseInt(timeStr, 10)

    // For each value at this timestamp
    Object.entries(values).forEach(([measureName, value]) => {
      // Initialize the measure array if it doesn't exist
      if (!result[measureName]) {
        result[measureName] = []
      }

      // Add the time-value pair to the appropriate measure array
      result[measureName].push({ time, value })
    })
  })

  return result
}

/**
 * Parses summary data from CalculatedMeasurePoint array
 *
 * This function extracts summary values from the first data point in the array,
 * converting all values to numbers and ensuring they are non-negative.
 *
 * @example
 * Example input data:
 * [
 *   {
 *     time: "2023-01-01T12:00:00Z",
 *     shift: "all",
 *     totalCount: 150,
 *     avgRate: 25.5,
 *     active: true
 *   }
 * ]
 *
 * @param data - Array of CalculatedMeasurePoint objects containing summary data (only one value)
 * @returns A record mapping measure names to their numeric values, excluding 'time' and 'shift' fields
 */
function parseSummaryData(data: CalculatedMeasurePoint[]): Record<string, number> {
  if (!data || data.length === 0) {
    return {}
  }

  // Take the first data point to extract summary values
  const firstPoint = data[0]

  // Create a summary object excluding 'time' and 'shift' fields
  const summary: Record<string, number> = {}

  Object.entries(firstPoint).forEach(([key, value]) => {
    if (key !== 'time' && key !== 'shift') {
      // Ensure values are numbers and default to 0 for negative values
      const numValue = typeof value === 'boolean' ? Number(value) : Number(value) || 0
      summary[key] = numValue >= 0 ? numValue : 0
    }
  })

  return summary
}

/**
 * Parses MeasurePoint or CalculatedMeasurePoint arrays into a standardized format
 */
function parseInfluxResponse(
  data: MeasurePoint[] | CalculatedMeasurePoint[],
  options: ParseOptions = {}
): ParsedSerieDatum[] | Record<string, ParsedSerieDatum[]> | ParsedStateData {
  if (!data || data.length === 0) {
    return options.measureType === 'summary' ? {} : []
  }

  // Type guard to check if we're dealing with standard measure points
  const isStandardMeasure = (points: MeasurePoint[] | CalculatedMeasurePoint[]): points is MeasurePoint[] => {
    return points.length > 0 && 'measure' in points[0] && !('shift' in points[0])
  }

  // Type guard to check if we're dealing with calculated measure points
  const isCalculatedMeasure = (
    points: MeasurePoint[] | CalculatedMeasurePoint[]
  ): points is CalculatedMeasurePoint[] => {
    return points.length > 0 && 'shift' in points[0]
  }

  if (options.measureType === 'standard' && isStandardMeasure(data)) {
    return parseStandardMeasure(data)
  } else if (options.measureType === 'word' && isStandardMeasure(data) && 'wordSlug' in options) {
    return parseWordMeasure(data, options.wordSlug)
  } else if (options.measureType === 'single_column_state_serie' && isCalculatedMeasure(data)) {
    return parseSingleColumnStateSerie(data)
  } else if (options.measureType === 'multi_column_state_serie' && isCalculatedMeasure(data)) {
    return parseMultiColumnStateSerie(data)
  } else if (options.measureType === 'summary' && isCalculatedMeasure(data)) {
    return parseSummaryData(data)
  }

  // If type doesn't match data or unknown type, return empty array
  return []
}

export { parseInfluxResponse, ParsedSerieDatum, ParsedStateData }
