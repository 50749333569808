/**
 * Core measure definitions and mappings
 */
import { BitMeasure, WordMeasure, AdditionalMeasure, WordSlug, SupportedMeasure } from './types'
import { getBitValue } from './binary-utils'

/**
 * Maps word slugs to their corresponding bit positions
 */
const WORD_SLUG_TO_BIT_POSITION: Record<WordSlug, Partial<Record<BitMeasure, number>>> = {
  [WordSlug.MDIR]: {
    [BitMeasure.Manual]: 0,
    [BitMeasure.Automatic]: 1,
    [BitMeasure.Local]: 2,
    [BitMeasure.Sectioned]: 3,
    [BitMeasure.Running]: 6,
  },
  [WordSlug.INV]: {
    [BitMeasure.Manual]: 0,
    [BitMeasure.Automatic]: 1,
    [BitMeasure.Local]: 2,
    [BitMeasure.Sectioned]: 3,
    [BitMeasure.Forward]: 6,
    [BitMeasure.Backward]: 7,
  },
  [WordSlug.SYNC]: {
    [BitMeasure.Sync]: 6,
  },
  [WordSlug.KH]: {
    [BitMeasure.ValveOpenPos]: 10,
    [BitMeasure.ValveClosedPos]: 11,
  },
  [WordSlug.PKEY]: {
    [BitMeasure.Key1Inserted]: 0,
    [BitMeasure.Key2Inserted]: 1,
    [BitMeasure.Key3Inserted]: 2,
    [BitMeasure.Key4Inserted]: 3,
    [BitMeasure.Key5Inserted]: 4,
    [BitMeasure.Key6Inserted]: 5,
    [BitMeasure.Key7Inserted]: 6,
    [BitMeasure.Key8Inserted]: 7,
    [BitMeasure.Key9Inserted]: 8,
    [BitMeasure.Key10Inserted]: 9,
    [BitMeasure.Key11Inserted]: 10,
    [BitMeasure.Key12Inserted]: 11,
    [BitMeasure.Key13Inserted]: 12,
    [BitMeasure.Key14Inserted]: 14,
  },
}

/**
 * Maps each word slug to the measures that can be derived from it
 */
const WORD_SLUG_TO_MEASURES: Record<WordSlug, WordMeasure[]> = {
  [WordSlug.MDIR]: [WordMeasure.Command, WordMeasure.Running],
  [WordSlug.INV]: [WordMeasure.Command, WordMeasure.Direction],
  [WordSlug.SYNC]: [WordMeasure.Sync],
  [WordSlug.KH]: [WordMeasure.ValvePosition],
  [WordSlug.PKEY]: [
    WordMeasure.Key1Inserted,
    WordMeasure.Key2Inserted,
    WordMeasure.Key3Inserted,
    WordMeasure.Key4Inserted,
    WordMeasure.Key5Inserted,
    WordMeasure.Key6Inserted,
    WordMeasure.Key7Inserted,
    WordMeasure.Key8Inserted,
    WordMeasure.Key9Inserted,
    WordMeasure.Key10Inserted,
    WordMeasure.Key11Inserted,
    WordMeasure.Key12Inserted,
    WordMeasure.Key13Inserted,
    WordMeasure.Key14Inserted,
  ],
}

/**
 * Gets all supported measures (both word-based and additional)
 *
 * @returns An array of all supported measures
 */
function getAllSupportedMeasures(): SupportedMeasure[] {
  const wordMeasures = Object.values(WordMeasure)
  const additionalMeasures = Object.values(AdditionalMeasure)

  return [...wordMeasures, ...additionalMeasures]
}

/**
 * Gets all measures that can be derived from a given word slug
 *
 * @param wordSlug - The word slug to get measures for
 * @returns An array of measures that can be derived from the word slug
 */
function getMeasuresForWordSlug(wordSlug: WordSlug): WordMeasure[] {
  return WORD_SLUG_TO_MEASURES[wordSlug] || []
}

/**
 * Extracts a bit value from a word at the specified position for a given word slug
 *
 * @param bitMeasure - The bit measure to extract
 * @param wordSlug - The word slug that contains the bit
 * @param wordValue - The value of the word
 * @returns The extracted bit value, or undefined if the bit is not present in the word
 */
function extractBitValue(bitMeasure: BitMeasure, wordSlug: WordSlug, wordValue: number): number | undefined {
  const bitPosition = WORD_SLUG_TO_BIT_POSITION[wordSlug]?.[bitMeasure]

  if (bitPosition === undefined) {
    return undefined
  }

  return getBitValue(wordValue, bitPosition)
}

/**
 * Checks if a measure is derived from a word
 *
 * @param measure - The measure to check
 * @returns true if the measure is derived from a word, false otherwise
 */
function isWordMeasure(measure: SupportedMeasure): measure is WordMeasure {
  return Object.values(WordMeasure).includes(measure as WordMeasure)
}

/**
 * Checks if a measure is additional (not derived from a word)
 *
 * @param measure - The measure to check
 * @returns true if the measure is additional, false otherwise
 */
function isAdditionalMeasure(measure: SupportedMeasure): measure is AdditionalMeasure {
  return Object.values(AdditionalMeasure).includes(measure as AdditionalMeasure)
}

/**
 * Checks if a string is a valid word slug
 *
 * @param slug - The string to check
 * @returns true if the string is a valid word slug, false otherwise
 */
function isWordSlug(slug: string): slug is WordSlug {
  return Object.values(WordSlug).includes(slug as WordSlug)
}

export {
  WORD_SLUG_TO_BIT_POSITION,
  WORD_SLUG_TO_MEASURES,
  getAllSupportedMeasures,
  getMeasuresForWordSlug,
  extractBitValue,
  isWordMeasure,
  isAdditionalMeasure,
  isWordSlug,
}
