import { mvDate } from '@mv-submodules/mvlabs-components-fe/functions/helpers'
import { API } from '../../../redux/actions'
import { IInfluxResponse, InfluxMetricQueryParams } from '@mv-submodules/inplant-plantanalysis-fe-iblu/types/influx'
import { shiftsNumber } from '@mv-submodules/inplant-plantanalysis-fe-iblu/constants/shift'

class InfluxMeasureFetcher {
  private plant: any
  private workshift: any
  private startOfDay: string
  private endOfDay: string
  private startDate: string
  private endDate: string
  private abortController: any

  constructor(plant: any, workshift: any, date: string, abortController: any) {
    this.plant = plant
    this.workshift = workshift
    this.startOfDay = this.computeStartOfTheDay(date)
    this.endOfDay = this.computeEndOfTheDay(date)
    this.startDate = this.computeStartDate(workshift, date)
    this.endDate = this.computeEndDate(workshift, date)
    this.abortController = abortController
  }

  private computeStartOfTheDay(date: string) {
    return mvDate.format(mvDate.startOfDay(mvDate.getDateFromString(date)), 'yyyy-MM-dd HH:mm:ss')
  }

  private computeStartDate(workshift: any, date: string) {
    return workshift?.start
      ? mvDate.getDateFromString(workshift.start.toString()).toISOString()
      : this.computeStartOfTheDay(date)
  }

  private computeEndOfTheDay(date: string) {
    return mvDate.format(mvDate.endOfDay(mvDate.getDateFromString(date)), 'yyyy-MM-dd HH:mm:ss')
  }

  private computeEndDate(workshift: any, date: string) {
    return workshift?.end
      ? mvDate.getDateFromString(workshift.end.toString()).toISOString()
      : this.computeEndOfTheDay(date)
  }

  private getQueryStart(metricQueryParams: InfluxMetricQueryParams) {
    if (metricQueryParams.type === 'standard' && metricQueryParams.aggregate) {
      return `SELECT PERCENTILE(*, 95) FROM`
    }
    return `SELECT * FROM`
  }

  private getQueryEnd(metricQueryParams: InfluxMetricQueryParams) {
    const timeRangeQuery = `time >= '${this.startDate}' AND time <= '${this.endDate}'`
    const dayRangeQuery = `time >= '${this.startOfDay}' AND time <= '${this.endOfDay}'`
    if (metricQueryParams.type === 'standard' && metricQueryParams.aggregate) {
      return `WHERE ${timeRangeQuery} GROUP BY time(1m) fill(linear)`
    }
    if (metricQueryParams.type === 'state-serie') {
      if (this.workshift.value === 99) {
        return `WHERE ${shiftsNumber.reduce((acc, n, index, arr) => {
          if (index === arr.length - 1) {
            return `${acc} shift = ${n}`
          }
          return `${acc} shift = ${n} OR`
        }, '')} AND ${timeRangeQuery}`
      }
      return `WHERE shift = ${this.workshift.value} AND ${timeRangeQuery}`
    }
    if (metricQueryParams.type === 'summary') {
      return `WHERE shift = ${this.workshift.value !== 99 ? this.workshift.value : 0} AND ${dayRangeQuery}`
    }
    return `WHERE ${timeRangeQuery}`
  }

  private buildQueryString(metricQueryParams: InfluxMetricQueryParams) {
    const queryStart = this.getQueryStart(metricQueryParams)
    const queryEnd = this.getQueryEnd(metricQueryParams)
    const plantQueryString = `plant=${this.plant}`
    return `/query?${plantQueryString}&q=${queryStart} ${metricQueryParams.id} ${queryEnd}`
  }

  public request(metricQueryParams: InfluxMetricQueryParams): Promise<IInfluxResponse>
  public request(metricQueryParams: InfluxMetricQueryParams[]): Array<Promise<IInfluxResponse>>

  public request(
    metricQueryParams: InfluxMetricQueryParams | InfluxMetricQueryParams[]
  ): Promise<IInfluxResponse> | Array<Promise<IInfluxResponse>> {
    if (Array.isArray(metricQueryParams)) {
      return metricQueryParams.map(queryParams => {
        return (API().request(this.buildQueryString(queryParams), {
          signal: this.abortController.signal,
        }) as Promise<string>).then(response => JSON.parse(response))
      })
    } else {
      return (API().request(this.buildQueryString(metricQueryParams), {
        signal: this.abortController.signal,
      }) as Promise<string>).then(response => JSON.parse(response))
    }
  }
}

export { InfluxMeasureFetcher }
