import { logoutUser } from '../../../../../../inplant-core-fe/redux/actions'
import { EngineTorqueDensifierProps, EngineTorqueDensifierState } from './_types'
import { ChartFetch } from '../../ChartRenderUtilities'
import { parseDuration } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/sharedV2'
import { InfluxMeasureFetcher } from '@mv-submodules/inplant-plantanalysis-fe-iblu/functions/influx/legacy/InfluxMeasureFetcher'
import { engineTorqueDensifierMeasuresToFetch } from './_measures'

interface DataGroupedByDec {
  [bufferKey: string]: { 
    [metricKey: string]: any;
  };
}

abstract class TorqueBelt<Props extends EngineTorqueDensifierProps, State extends EngineTorqueDensifierState> extends ChartFetch<
  Props,
  State
> {
  // private mounted = false
  private abortController: AbortController = new AbortController()
  protected chartTranslationKeyTitle: string = 'plantAnalysis.lines.engineTorqueDensifierTitle'

  constructor(props: Props, generateState: (props: Props) => State) {
    super(props)
    this.state = generateState(props)
    this.getData = this.getData.bind(this)
  }

  public componentDidMount() {
    this.getData(this.props.processing)
  }

  public componentWillUnmount() {
    this.abortController.abort()
  }

  protected getSecondsPercentFromTotalWorkshift(lineSecondsAtZero: number): number | undefined {
    const workShiftTotalSeconds = this.getWorkshiftTotalSeconds()
    if(workShiftTotalSeconds){
      return (lineSecondsAtZero / workShiftTotalSeconds) * 100
    }
    return undefined
  }

  protected getWorkshiftTotalSeconds(){
    const { workshift } = this.props
    if (workshift) {
      if(workshift.value === 99){
        return parseDuration("24h 00m").asSeconds()
      }else{
        return parseDuration(workshift.h).asSeconds()
      }
    }
    return undefined
  }

  public componentDidUpdate(prevProps: Readonly<EngineTorqueDensifierProps>) {
    if (
      this.props.dateFilterStart !== prevProps.dateFilterStart ||
      this.props.dateFilterEnd !== prevProps.dateFilterEnd ||
      this.props.workShift?.value !== prevProps.workShift?.value ||
      (this.props.plant.plant !== prevProps.plant.plant && !prevProps.plant.isLoading)
    ) {
      if (this.props.dateFilterStart && this.props.dateFilterEnd) {
        this.getData(this.props.processing)
      }
    }
  }

  private formatData(data: Record<string, number>) {
    try {
      const dataGrouped = Object.entries(data).reduce<DataGroupedByDec>((acc, [key, value]) => {
        const match = key.match(/(dec_f\d+)_([\w_]+)/);
        if (match) {
          const [, bufferKey, metricKey] = match;
          (acc[bufferKey] ??= {})[metricKey] = value;
        }
        return acc;
      }, {});

      this.setState({
        data: dataGrouped,
        fetching: false,
      })
    } catch (error) {
      this.setState({
        data: null,
        fetching: false,
        error: true,
      })
    }
  }

  private async getData(processing?: number) {
    // const { plant } = this.props.plant
    const workshift = this.props.workshift

    if(workshift){
      try{
        this.setState({
          fetching: true,
          error: false,
        })
        const InfluxFetcher = new InfluxMeasureFetcher(
          this.props.plant.plant,
          this.props.workshift,
          this.props.date.toString(),
          this.abortController
        )
        const promises = InfluxFetcher.request(engineTorqueDensifierMeasuresToFetch)
        const promisesResolved = await Promise.all(promises)
        const promisesParsed = promisesResolved.reduce<{decSpeedTorque?: any, decUptime?: any}>((acc, value, index) => {
          const measureSlug = engineTorqueDensifierMeasuresToFetch[index].slug
          const parseFn = engineTorqueDensifierMeasuresToFetch[index].parseFn
          const dataParsed = parseFn(value)
          return {
            ...acc,
            [measureSlug]: dataParsed,
          }
        }, {})
        this.formatData({
          ...promisesParsed.decSpeedTorque,
          ...promisesParsed.decUptime,
        })

      }catch(error: any){
        if (error.name === 'FetchError' && error.statusCode === 401) {
          logoutUser()
        }
        this.setState({
          fetching: false,
          error: true,
        })
      }
    }
  }

  protected abstract renderContent(): JSX.Element
  public render() {
    const { fetching, error } = this.state

    if (fetching) {
      return this.renderLoading()
    }

    if (error) {
      return this.renderError()
    }

    if (!this.state.data) {
      return this.renderNoData()
    }

    return this.renderContent()
  }
}

export default TorqueBelt
